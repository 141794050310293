import axios, { AxiosInstance, RawAxiosRequestHeaders } from 'axios';
import { APIGetPaginatedParams } from './types';
import { SurveyTemplateRecord } from '../models/SurveyTemplate';

export interface SurveyTemnplatesGetPaginatedParams
    extends APIGetPaginatedParams {
    clientId?: string;
}

class SurveyTemplateAPI {
    private static getSurveyTemplateAPIAxiosObj = (
        path = '/templates'
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        const baseURL =
            process.env.REACT_APP_SURVEY_TEMPLATE_API_BASEURL! + path;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static getTemplate = async (
        templateId: string
    ): Promise<SurveyTemplateRecord> => {
        const surveyTemplateAPI = this.getSurveyTemplateAPIAxiosObj();

        const { status, data } =
            await surveyTemplateAPI.get<SurveyTemplateRecord>(`/${templateId}`);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving survey template');
        }
    };
}

export default SurveyTemplateAPI;
