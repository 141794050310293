import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFound from '../../pages/NotFound';
import SurveyPage from '../../pages/surveys/SurveyPage';
import SurveyPerformYardPage from '../../pages/surveys/SurveyPerformYardPage';
import RedirectPage from '../../pages/trackingLinks/RedirectPage';

export enum RoutePaths {
    Survey = 'survey',
    G2Review = 'g2review',
    Redirect = 'redir'
}

const AppRouter = (props: React.PropsWithChildren): JSX.Element => {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route
                        path={RoutePaths.Redirect}
                        element={<RedirectPage />}
                    />
                    <Route path={RoutePaths.Survey} element={<SurveyPage />} />
                    <Route
                        path={RoutePaths.G2Review}
                        element={<SurveyPerformYardPage />}
                    />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
            {props.children}
        </BrowserRouter>
    );
};

export default AppRouter;
