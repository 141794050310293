import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SurveyResponseAPI from '../../api/SurveyResponseAPI';
import { Container, Typography } from '@mui/material';
import Logger from '../../utils/logger';
import SubmissionAPI from '../../api/SubmissionAPI';
import Overlay from '../../components/core/Overlay/Overlay';
import SurveyTemplateAPI from '../../api/SurveyTemplateAPI';

const SurveyPage = (): JSX.Element => {
    const queryParams = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();
    const [surveyCompleted, setSurveyCompleted] = useState<boolean>(false);
    const [survey, setSurvey] = useState<Model | undefined>(undefined);

    const onSurveyComplete = async (sender: Model) => {
        const result = await SurveyResponseAPI.submit({
            ...sender.data,
            id: queryParams.get('id')
        });

        if (result) {
            Logger.error('Error submitting reponse to the response service');
            navigate('/survey-error');
        }
    };

    const confirmSurveyValid = async (): Promise<void> => {
        const submissionId = String(queryParams.get('id'));

        if (survey === undefined) {
            try {
                // Validate submission exists and has no response
                await SurveyResponseAPI.validate(submissionId);

                // Get survey id from submission
                const submissionSurvey = await SubmissionAPI.getSurvey(
                    submissionId
                );

                if (submissionSurvey.surveyId) {
                    const surveyTemplate = await SurveyTemplateAPI.getTemplate(
                        submissionSurvey.surveyId
                    );

                    const surveyModel = new Model(
                        JSON.parse(surveyTemplate.code)
                    );

                    // Customized theme
                    // survey.applyTheme(theme);

                    surveyModel.onComplete.add(onSurveyComplete);

                    // Set some template variables
                    surveyModel.setVariable('domain', window.location.origin);
                    surveyModel.setVariable(
                        'campaignId',
                        submissionSurvey.campaignId
                    );
                    surveyModel.setVariable('submissionId', submissionId);
                    setSurvey(surveyModel);
                }
            } catch (err: unknown) {
                Logger.error(JSON.stringify(err));
                setSurveyCompleted(true);
            }
        }
    };

    useEffect(() => {
        if (!queryParams.has('id')) {
            navigate('/404');
            return;
        }

        if (queryParams.has('id') && survey === undefined) {
            confirmSurveyValid();
        }
    }, []);

    return (
        <Container sx={{ maxWidth: '1000px' }}>
            <Overlay processing={survey === undefined} />
            {survey && <Survey model={survey} />}
            {surveyCompleted && (
                <Typography variant='h4' sx={{ mx: 'auto', mt: '10rem' }}>
                    Thanks for completing our survey! Your feedback is greatly
                    appreciated.
                </Typography>
            )}
        </Container>
    );
};

export default SurveyPage;
