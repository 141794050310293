import axios, { AxiosInstance, RawAxiosRequestHeaders } from 'axios';
import { TrackingLinkCategory } from '../models/TrackingLink';
import Logger from '../utils/logger';

export enum SelectRuleLogic {
    Equal = 'equal',
    GreaterThan = 'greaterThan',
    Exists = 'exists'
}

export interface SelectRule {
    property: string;
    logic: SelectRuleLogic;
    value?: string | number;
}

interface TrackingLinkPostBody {
    campaignId: string;
    url: string;
    category?: TrackingLinkCategory;
    submissionId?: string;
}

class CampaignAPI {
    private static getCampaignAPIAxiosObj = (
        basePath: string = '/campaigns'
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        const baseURL = process.env.REACT_APP_CAMPAIGN_API_BASEURL! + basePath;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static submitTrackingLink = async (
        campaignId: string,
        url: string,
        category?: TrackingLinkCategory,
        submissionId?: string
    ): Promise<void> => {
        const campaignAPI = this.getCampaignAPIAxiosObj('/tracking-links');

        try {
            const body: TrackingLinkPostBody = {
                campaignId,
                url
            };

            if (category) {
                body.category = category;
            }

            if (submissionId) {
                body.submissionId = submissionId;
            }

            const response = await campaignAPI.post('', body);

            if (response.status !== 200) {
                Logger.error(
                    `Submitting tracking link: ${JSON.stringify(response)}`
                );
            }
        } catch (err: unknown) {
            Logger.error(`Submitting tracking link: ${JSON.stringify(err)}`);
        }
    };
}

export default CampaignAPI;
