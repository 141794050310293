import axios, {
    AxiosError,
    AxiosInstance,
    RawAxiosRequestHeaders
} from 'axios';
import { NewSurveyResponse } from '../models/SurveyResponse';
import { ApiErrorObject } from './types';
import {
    apiErrorsToConsolidatedErrorObject,
    generateApiUnexpectedError
} from './utils/data-management';

export interface G2GetTokenResponse {
    state: string;
}

class SurveyResponseAPI {
    private static getResponseAPIAxiosObj = (): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        const baseURL = process.env.REACT_APP_SURVEY_RESPONSE_API_BASEURL!;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static submit = async (
        responseData: NewSurveyResponse
    ): Promise<ApiErrorObject | undefined> => {
        const surveyResponseAPI = SurveyResponseAPI.getResponseAPIAxiosObj();

        try {
            await surveyResponseAPI.post('/responses', responseData);
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('submitting survey response');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return;
    };

    public static validate = async (submissionId: string): Promise<boolean> => {
        const surveyAPI = SurveyResponseAPI.getResponseAPIAxiosObj();

        const { status, data } = await surveyAPI.get<boolean>(
            `/responses/${submissionId}/validate`
        );

        if (status === 200 && typeof data === 'boolean') {
            return data;
        } else {
            throw new Error(`Error validating submission id ${submissionId}`);
        }
    };

    public static getState = async (
        clientId: string
    ): Promise<G2GetTokenResponse> => {
        const surveyAPI = SurveyResponseAPI.getResponseAPIAxiosObj();

        const { status, data } = await surveyAPI.get<G2GetTokenResponse>(
            `/integrations/g2/state/${clientId}`
        );

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error(`Error retrieving survey state token from G2`);
        }
    };
}

export default SurveyResponseAPI;
