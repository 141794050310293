import axios, { AxiosInstance, RawAxiosRequestHeaders } from 'axios';
import { APIGetPaginatedParams } from './types';

export interface SubmissionSurveyResponse {
    surveyId: string;
    campaignId: string;
    clientId: string;
}

export interface EmailTemnplatesGetPaginatedParams
    extends APIGetPaginatedParams {
    clientId?: string;
}

class SubmissionAPI {
    private static getSubmissionAPIAxiosObj = (): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        const baseURL = process.env.REACT_APP_SUBMISSION_API_BASEURL!;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static getSurvey = async (
        submissionId: string
    ): Promise<SubmissionSurveyResponse> => {
        const submissionAPI = this.getSubmissionAPIAxiosObj();

        const { status, data } =
            await submissionAPI.get<SubmissionSurveyResponse>(
                `/submissions/${submissionId}/survey`
            );

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error(
                `Error retrieving survey from submission id ${submissionId}`
            );
        }
    };
}

export default SubmissionAPI;
