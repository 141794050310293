import 'survey-core/defaultV2.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Logger from '../../utils/logger';
import SurveyResponseAPI from '../../api/SurveyResponseAPI';
import { GetG2APISurveyFormEmbedUrl } from '../../api/G2API';
import SubmissionAPI from '../../api/SubmissionAPI';

const SurveyPerformYardPage = (): JSX.Element => {
    const queryParams = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();
    const [surveyForm, setSurveyForm] = useState<string>('');

    const getSurveyFormUrl = async () => {
        const submissionId = String(queryParams.get('id'));
        const email = decodeURIComponent(String(queryParams.get('email')));

        try {
            await SurveyResponseAPI.validate(submissionId);
            const response = await SubmissionAPI.getSurvey(submissionId);
            const stateToken = await SurveyResponseAPI.getState(
                response.clientId
            );
            const url = `${GetG2APISurveyFormEmbedUrl(
                process.env.REACT_APP_G2_API_BASEURL!,
                stateToken.state,
                email
            )}`;
            setSurveyForm(url);
        } catch (err: unknown) {
            Logger.error(JSON.stringify(err));
        }
    };

    useEffect(() => {
        if (!queryParams.has('id') || !queryParams.has('email')) {
            navigate('/404');
            return;
        }
        if (queryParams.has('id')) {
            getSurveyFormUrl();
        }
    }, []);

    return (
        <Container sx={{ maxWidth: '1000px' }}>
            <iframe
                src={surveyForm}
                style={{ width: '100%', height: '1150px', border: 'none' }}
            ></iframe>
        </Container>
    );
};

export default SurveyPerformYardPage;
